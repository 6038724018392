import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import AboutUs from './pages/innerpages/AboutUs';
import Admin from './pages/innerpages/Admin';
import BITSAdmin from './pages/innerpages/BITSAdmin';
import ContactUs from './pages/innerpages/ContactUs';
import Faq from './pages/innerpages/Faq';
import HomeFour from './pages/homepages/HomeFour';
import PhotoPage from './pages/innerpages/PhotoPage';
import PrivacyPolicy from './pages/innerpages/PrivacyPolicy';
import Error from './pages/innerpages/Error';
import Pricing from './pages/innerpages/Pricing';
import TermsAndConditions from './pages/innerpages/TermsAndConditions';
import ThanksBuinessEmail from './pages/innerpages/ThanksBuinessEmail';

import CancellationRefundPolicy from './pages/innerpages/CancellationRefundPolicy';
import { GlobalProvider } from './context/global_context';

// Import Css Here 
import './assets/scss/style.scss';
import Profile from './pages/innerpages/Profile';
import Examples from './pages/innerpages/Examples';
import History from './pages/innerpages/History';
import Teams from './pages/innerpages/Teams';

import FacePhoto from './pages/innerpages/FacePhoto';
import FaceReplacePhoto from './pages/innerpages/FaceReplacePhoto';
import AnyAIPhoto from './pages/innerpages/AnyAIPhoto';
import Blogs from './pages/innerpages/Blogs';
import Blog from './pages/innerpages/Blog';
import DeleteUser from './pages/innerpages/DeleteUser';

function App() {
    return (
        <GlobalProvider>
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route exact path='/' element={<HomeFour/>}/>
                    <Route exact path='/home' element={<HomeFour/>}/>
                    
                    <Route exact path={`${'/about-us'}`} element={<AboutUs/>}/>
                    <Route exact path={`${'/adda-007'}`} element={<Admin/>}/>
                    <Route exact path={`${'/bits-admin-dashboard'}`} element={<BITSAdmin/>}/>
                    <Route exact path={`${'/ai-portrait'}`} element={<FacePhoto/>}/>
                    <Route exact path={`${'/face-replacement'}`} element={<FaceReplacePhoto/>}/>
                    <Route exact path={`${'/ai-photos'}`} element={<AnyAIPhoto/>}/>
                    
                    <Route exact path={`${'/contact-us'}`} element={<ContactUs/>}/>
                    <Route exact path={`${'/thanks-business'}`} element={<ThanksBuinessEmail/>}/>
                    
                    <Route exact path={`${'/delete-account'}`} element={<DeleteUser/>}/>

                    <Route exact path={`${'/faq'}`} element={<Faq/>}/>
                    <Route exact path={`${'/privacy-policy'}`} element={<PrivacyPolicy/>}/>
                    <Route exact path={`${'/my-profile'}`} element={<Profile/>}/>
                    <Route exact path={`${'/ai-photo-examples'}`} element={<Examples/>}/>
                    <Route exact path={`${'/history'}`} element={<History/>}/>
                    <Route exact path={`${'/instaphotoai-teams'}`} element={<Teams/>}/>

                    <Route exact path={`${'/pricing'}`} element={<Pricing/>} />
                    <Route exact path={`${'/blogs'}`} element={<Blogs/>} />
                    <Route exact path={`${'/blogs/:id'}`} element={<Blog/>} />

                    <Route exact path={`${'/terms-and-conditions'}`} element={<TermsAndConditions/>} />
                    <Route exact path={`${'/cancellation-refund-policy'}`} element={<CancellationRefundPolicy/>} />

                    <Route exact path='/photo/:id' element={<PhotoPage/>}/>

                    <Route path='*' element={<Error/>}/>
                </Routes>
            </ScrollToTop>
        </Router>
        </GlobalProvider>
    );
}

export default App;
