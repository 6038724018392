import React, {useRef, useState} from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import LayoutAfterLogin from '../../common/LayoutAfterLogin';
import BannerFour from '../../components/banner/BannerFour';
import FaceReplacement from '../../components/home-four/FaceReplacement';
import AIFacePhotoCreation from '../../components/home-four/AIFacePhotoCreation';
import AIPhotoCreation from '../../components/home-four/AIPhotoCreation';
import AnyAIPhotoCreation from '../../components/home-four/AnyAIPhotoCreation';
import HomeFourAbout from '../../components/home-four/HomeFourAbout';
import HowTo from '../../components/home-five/HowTo';
import PricePlans from '../../components/pricing/PricePlans';
import CustomImages from '../../components/pricing/CustomImages';

import BlogCard from '../../components/blogs/BlogCard';

import { useGlobalContext } from '../../context/global_context';

const HomeFour = () => {
    const {
        user,
        path,
        updatePath,
        updateRegisterEmail,
        deleteUser
    } = useGlobalContext();

    if(user && user.token) {
        window.location.href = "/ai-portrait";
    } else if (path != '/') {
        updatePath('/')
    }
    const [deleted, setDeleted] = useState(false);

    const contactRef = useRef(null);

    return (
        <>
            <SEO title="InstaPhotoAI" />

            {(!user || !user.token) ?
                <Layout>
                    <BannerFour />
                    <AIFacePhotoCreation />
                    <FaceReplacement /> 
                    <AIPhotoCreation/>
                    <AnyAIPhotoCreation/>
                    <HowTo/>
                    <hr/>
                    <PricePlans/>
                    <CustomImages/>
                    <HomeFourAbout />
                    <BlogCard />
                    <div id="delete-account" ref={contactRef}>
                        
                    <div className="edu-section-gap container edu-pricing-area edu-section-gap bg-image">
                    <div className={`mobile-pricing-table`}>
                    <h3>Delete An Existing User Account</h3>
                        <div className="pricing-header">
                            <h3 className="title">Provide emailId to delete user from RainForest AI</h3>
                            {!deleted ? <><input style={{ width: "200px" }} type="email" placeholder="Email" onChange={updateRegisterEmail} />
                                <br />
                                <br />
                                <button className='edu-btn btn-small' onClick={() => {setDeleted(true); deleteUser();}}>Delete</button></>
                                : <p style={{color:'green'}}> User Deleted
                                </p>}
                        </div>
                    </div>
                </div>
                    </div>
                </Layout>
                :
                <LayoutAfterLogin/>      
            }
        </>
    )
}

export default HomeFour;