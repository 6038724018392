import React from 'react';
import { useGlobalContext } from '../../context/global_context';
import { useAlert } from 'react-alert';
import SectionTitle from '../sectionTitle/SectionTitle';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const inIndia = timeZone.indexOf('Calcutta') >= 0;

const plans = [
    {
        id: 1,
        name: 'SILVER',
        price: inIndia ? '₹699' : '$9',
        priceText: inIndia ? '₹699' : '$9',
        priceType: 'Monthly',
        buttonText: 'Buy Subscription',
        purchaseLink: '/pricing',
        photoCredits: 500,
        features: [
            '500 photos/month',
            'Best For hobbyists'
        ]
    },
    {
        id: 2,
        name: 'GOLD',
        price: inIndia ? '₹1499' : '$19',
        priceText: inIndia ? '₹1,499' : '$19',
        priceType: 'Monthly',
        badgeText: 'MOST POPULAR',
        buttonText: 'Buy Subscription',
        purchaseLink: '/pricing',
        active: true,
        photoCredits: 2000,
        features: [
            '2000 photos/month',
            'Best For Creators'
        ]
    },
    {
        id: 3,
        name: 'PLATINUM',
        price: inIndia ? '₹3999' : '$49',
        priceText: inIndia ? '₹3,999' : '$49',
        priceType: 'Monthly',
        buttonText: 'Buy Subscription',
        purchaseLink: '/pricing',
        photoCredits: 5000,
        features: [
            '5000 photos/month',
            'Best For Small Teams'
        ]
    }

];

const PricePlans = ( { wrapperClass, itemClass } ) => {
    const alert = useAlert()

    const {
        user,
        pay,
        isMobile,
        setNeedLogin
    } = useGlobalContext();

    const onBuyNowClick = ({purchaseLink, membershipType, price, duration, photoCredits}) => {
        if(!user || !user.token) {
            setNeedLogin(true);
            alert.info("Login to create many custom stunning photos.");
        } else {
            pay(price, duration, membershipType, photoCredits);
        }
    }

    return (
        <div className="edu-section-gap container edu-pricing-area edu-section-gap bg-image">
            <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = "Most cost-effective pricing in AI Images"
                            title = "Our Plans"
                        />
                        
                    </div>
                </div>
                        {isMobile ? 
                        <>
                            {plans.map((data, index) => (
                                    <div className={`mobile-pricing-table ${data.active === true ? ' active' : ''}`}>
                                    <div className="row" key={index}>
                                        <div className="col-6" >
                                        <div className="pricing-header">
                                                {
                                                    data.active === true && <div className="edu-badge"><span>{data.badgeText}</span></div>
                                                }
                                                <h3 className="title">{data.name}</h3>
                                                <div className="price-wrap">
                                             
                                                <div className="yearly-pricing">
                                                    <span className="current-price">{data.priceText}</span>
                                                    <span className="description">{data.priceType}</span>
                                                    <br/>
                                                    <span className="old-price">{data.oldPriceText}</span> 
                                                    <br/>
                                                </div>
                                                </div>
                                                </div>
                                        </div>
                                        <div className="col-6" >
                                            <br/>
                                            {data.features && data.features.length > 0 &&
                                            <div className="pricing-body">
                                                    <ul className="list-item">
                                                        {
                                                            data.features.map((feature, i) => <li key={i}><i className="icon-checkbox-circle-line"></i>{feature}</li>)
                                                        }
                                                    </ul>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">

                                    <div className="col-lg-12" >
                                                <button className={`edu-btn btn-small ${data.active !== true ? ' btn-dark' : ''}`} style={{width:'100%'}} onClick={() => onBuyNowClick({purchaseLink: data.purchaseLink, membershipType: data.name, price: data.price, duration: "monthly", membershipType: data.name, photoCredits: data.photoCredits})}>
                                                    {data.buttonText}<i className="icon-arrow-right-line-right"></i>
                                                </button>
                                                <p style={{fontSize:'12px'}}>Cancel it anytime by sending an email to <a target="_blank" href="mailto:connect@instaphotoai.com">connect@instaphotoai.com</a></p>
                                        </div>
                                    </div>
                                    
                                </div>))
                            }
                        </>
                        : <div className="row g-5 mt--20">
                            {plans.map((data, index) => (
                                    <div className="col-lg-4 col-md-6 col-12" key={index}>
                                        <div className={`pricing-table ${data.active === true ? ' active' : ''}`}>
                                            <div className="pricing-header">
                                                {
                                                    data.active === true && <div className="edu-badge"><span>{data.badgeText}</span></div>
                                                }
                                                <h3 className="title">{data.name}</h3>
                                                <div className="price-wrap">
                                                    <div className="yearly-pricing price-list">
                                                        <span className="current-price">{data.priceText}</span>{data.priceType}
                                                        <br/>
                                                    </div>
                                                </div>
                                            </div>
                                            {data.features && data.features.length > 0 &&
                                                <div className="pricing-body">
                                                    <ul className="list-item">
                                                        {
                                                            data.features.map((feature, i) => <li key={i}><i className="icon-checkbox-circle-line"></i>{feature}</li>)
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                            <div className="pricing-btn">
                                                <button className={`edu-btn ${data.active !== true ? ' btn-dark' : ''}`} onClick={() => onBuyNowClick({purchaseLink: data.purchaseLink, membershipType: data.name, price: data.price, duration: "monthly", membershipType: data.name, photoCredits: data.photoCredits})}>
                                                    {data.buttonText}<i className="icon-arrow-right-line-right"></i>
                                                </button>
                                                <p style={{fontSize:'12px'}}>Cancel it anytime by sending an email to <a target="_blank" href="mailto:connect@instaphotoai.com">connect@instaphotoai.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>}
                    </div>
    )
}

export default PricePlans;