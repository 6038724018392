import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import PricePlans from '../../components/pricing/PricePlans';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const inIndia = timeZone.indexOf('Calcutta') >= 0;

const plans = [
    {
        id: 1,
        name: 'SILVER',
        price: inIndia ? '₹699' : '$9',
        oldPrice: inIndia ? '₹1499' : '$19',
        priceText: inIndia ? '₹699' : '$9',
        oldPriceText: inIndia ? '₹1,499' : '$19',
        priceType: 'Monthly',
        buttonText: 'Buy Subscription',
        purchaseLink: '/pricing',
        photoCredits: 500,
        features: [
            '500 photos/month',
            'Best For hobbyists'
        ]
    },
    {
        id: 2,
        name: 'GOLD',
        price: inIndia ? '₹1499' : '$19',
        oldPrice: inIndia ? '₹3999' : '$49',
        priceText: inIndia ? '₹1,499' : '$19',
        oldPriceText: inIndia ? '₹3,999' : '$49',
        priceType: 'Monthly',
        badgeText: 'MOST POPULAR',
        buttonText: 'Buy Subscription',
        purchaseLink: '/pricing',
        active: true,
        photoCredits: 2000,
        features: [
            '2000 photos/month',
            'Best For Creators'
        ]
    },
    {
        id: 3,
        name: 'PLATINUM',
        price: inIndia ? '₹3999' : '$49',
        oldPrice: inIndia ? '₹7,999' : '$99',
        priceText: inIndia ? '₹3,999' : '$49',
        oldPriceText: inIndia ? '₹7,999' : '$99',
        priceType: 'Monthly',
        buttonText: 'Buy Subscription',
        purchaseLink: '/pricing',
        photoCredits: 5000,
        features: [
            '5000 photos/month',
            'Best For Small Teams'
        ]
    }

];

const Pricing = () => {
    return (
        <>
            <SEO title="Pricing Plan" />
            <Layout>
                <BreadcrumbOne
                    title="Pricing Plan"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Pricing Plan"
                />
                <PricePlans/>
            </Layout>
        </>
    )
}

export default Pricing;