import React, { useState } from 'react';
import SEO from '../../common/SEO';
import LayoutAfterLogin from '../../common/LayoutAfterLogin';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CounterUpOne from '../../components/counterup/CounterUpOne';
import { useGlobalContext } from '../../context/global_context';
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { IconButton } from '@material-ui/core';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import ReactPlayer from 'react-player'
import Grid from '@material-ui/core/Grid'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import Checkbox from '@mui/material/Checkbox';
import Groups2Icon from '@mui/icons-material/Groups2';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const inIndia = timeZone.indexOf('Calcutta') >= 0;

const useStyles = makeStyles({
    bottomBtn: {
        fontSize: '12px',
        fontWeight: 'bold',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    fullscreenOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        cursor: 'pointer',
    },
    fullscreenImage: {
        img: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        }
    }
});

async function downloadBinaryPhoto(imageBinary) {
    try {
        // Create a temporary anchor element
        // const url = window.URL.createObjectURL("data:image/png;base64," + imageBinary);
        const link = document.createElement('a');
        link.href = "data:image/png;base64," + imageBinary;

        // Extract the filename from the URL
        const filename = "instaPhotoAI_output_" + Date.now() + ".png";

        // Set the download attribute and filename
        link.setAttribute('download', filename);
        document.body.appendChild(link);

        // Simulate a click on the anchor element to start the download
        link.click();

        // Clean up the temporary anchor element
        link.parentNode.removeChild(link);
    } catch (ex) {
        console.log("Error in downloading an image", ex.message)
    }
};

async function downloadPhoto(imageUrl) {
    fetch(imageUrl, {
        "mode": "cors",
        "headers": {
            "Content-Type": "application/json",
            "access-control-allow-origin": "*",
            'Cache-Control': 'no-cache'
        }
    })
        .then(response => response.blob())
        .then(blob => {
            // Create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            // Extract the filename from the URL
            const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

            // Set the download attribute and filename
            link.setAttribute('download', filename);
            document.body.appendChild(link);

            // Simulate a click on the anchor element to start the download
            link.click();

            // Clean up the temporary anchor element
            link.parentNode.removeChild(link);
        })
        .catch(error => {
            console.error('Error downloading image:', error);
        });
};

const FacePhoto = () => {
    const classes = useStyles();
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [fullPhotoSrc, setFullPhotoSrc] = useState(undefined);

    const handleImageClick = (photoSrc) => {
        setShowFullScreen(true);
        setFullPhotoSrc(photoSrc);
    };

    const handleCloseFullScreen = () => {
        setShowFullScreen(false);
        setFullPhotoSrc(undefined);
    };

    const {
        user,
        pay,
        demo,
        path,
        updatePath,
        log,
        themes,
        isMobile,
        updatedActiveMenu,
        generateNewPhotos,
        refreshUserDetails,
        fetchUser,
        setRefreshUserDetails,
        fetchThemes,
        activeMenu,
        addImageToTeam
    } = useGlobalContext();

    if (path != '/ai-portrait') {
        updatePath('/ai-portrait');
        updatedActiveMenu('ai-portrait');
        fetchUser();
    } else if(refreshUserDetails) {
        fetchUser();
        setRefreshUserDetails(false);
    }

    if(!themes || !themes.instaportraitai || themes.instaportraitai.length == 0) {
        fetchThemes("instaportraitai");
    }

    if(!isMobile && activeMenu !== 'ai-portrait' && activeMenu !== 'more') {
        updatedActiveMenu('ai-portrait'); 
    }

    return (
        <>
            <SEO title="Insta Photo AI | Custom Created Photo" />
            <LayoutAfterLogin>
                <BreadcrumbOne
                    title="AI Portrait"
                    parentUrl="Home"
                    currentUrl="AI Portrait"
                />
                {user && user.membership && user.membership.photosCredit <= 0 && <>
                    <div style={{ background: '#fef0e5', border: 'thick double #F86F03', margin: '10px', padding: '20px 60px' }}>
                        <b className="description">Your Photo Credit is Zero.</b>
                        <br />
                        <span className="description">Recharge now!!</span>
                        <div className="pricing-btn">
                            <button className={`edu-btn btn-small`} onClick={() => pay(inIndia ? '₹699' : '$9', "monthly", 'SILVER')}>
                                Buy 500 Credits at {inIndia ? '₹699' : '$9'} Now!!<i className="icon-arrow-right-line-right"></i>
                            </button>
                        </div>
                        <span>Or <u><a target="_blank" href="/pricing">Visit Pricing</a></u> to know all the plans.</span>

                    </div>
                </>}
                {user && user.membership && user.membership.photosCredit > 0 && <>
                    <a target="_blank" href="/pricing"><div style={{ background: '#e2dcfa', border: 'thick double #7152E9', margin: '2.5px', padding: '5px 15px' }}>
                        <span className="description">Remaining Photo Credit: {user.membership.photosCredit}</span>
                        <br />
                        <span><u>Visit Pricing</u> to know all the plans.</span>
                    </div></a>
                </>}

                {demo.intermediateImage 
                && demo.intermediateImage.base64
                &&
                <div>
                <img src={'data:image/png;base64,' + demo.intermediateImage.base64}/>
                </div>}
                
                    {(demo.photos
                        && demo.photos.length > 0)
                        &&
                        <>
                            <Grid
                                container
                                direction={"row"}
                                justifyContent="center"
                                alignItems='center'
                                spacing={1}
                            >
                                {demo.photos.map((photo, index) => {
                                    return <Grid key={index} item md={10} xs={10} style={{ padding: '4px', border: '1px' }}>
                                        {index === 0
                                            &&
                                            <span style={{ fontSize: "12px" }}>Click on the photos to see in the full-screen mode.</span>
                                        }
                                        <br /><b>{index + 1}/{demo.photos.length}</b>
                                        <img style={{ border: '1px solid #000', cursor: 'pointer' }}
                                            src={photo.imageAWSLink}
                                            alt="Generated Images"
                                            onClick={() => handleImageClick(photo.imageAWSLink)} />

                                        {showFullScreen && fullPhotoSrc && (
                                            <div
                                                className={classes.fullscreenOverlay}
                                                onClick={handleCloseFullScreen}
                                            >
                                                <div className={classes.fullscreenImage}>
                                                    <img
                                                        className="centered-image"
                                                        src={fullPhotoSrc}
                                                        alt="Full Screen"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <br />
                                        <button className="edu-btn btn-small btn-secondary" onClick={() => {
                                            downloadPhoto(photo.imageAWSLink[0]);
                                            log({ screenType: "Photo Screen", action: "Download Photo" })
                                        }}>
                                            Download <DownloadRoundedIcon fontSize='large' />
                                        </button>
                                        <span>   </span>
                                        {index === 0 &&
                                            <><button className="edu-btn btn-small btn-secondary" onClick={generateNewPhotos}>
                                                Recreate <RefreshIcon fontSize='large' />
                                            </button>
                                                {demo.apiCallDuration && <Grid
                                                    container
                                                    direction={"row"}
                                                    justifyContent="center"
                                                    alignItems='center'
                                                    spacing={1}
                                                >
                                                    <Grid item xs={8} style={{ padding: '4px', border: '1px' }}>
                                                        <>
                                                            <br /><span>Time Taken: <b>{demo.apiCallDuration} seconds</b></span>

                                                            {isMobile &&
                                                                <Button className='edu-btn btn-small' onClick={() => { updatedActiveMenu('ai-portrait'); }}
                                                                    color="primary">
                                                                    Create More AI Portraits
                                                                </Button>}                                    </>
                                                    </Grid>

                                                </Grid>}
                                            </>}
                                        {user.teams 
                                        && (Object.keys(user.teams).length > 0)
                                        && <div style={{ minWidth: '100%' }} className={classes.innerDiv}>
                                            {Object.values(user.teams).map(team =>
                                            {return <><Checkbox
                                                id={team._id}
                                                size='large'
                                                onClick={(e) => addImageToTeam(photo._id, team._id, e)}
                                                color="primary"
                                                /> <span style={{ fontSize: '12px' }}>{team.name}</span></>})
                                        }</div>}
                                    </Grid>
                                })}
                            </Grid>
                            <br />
                            <br />
                            <br />
                        </>}
                        <br/>
                    <Link to='/history'><button className='edu-btn btn-small btn-secondary'><WorkHistoryIcon fontSize='large' />Check My Recent Creations</button></Link>
                    <br/>
                    <Link to='/instaphotoai-teams'><button className='edu-btn btn-small btn-secondary'><Groups2Icon fontSize='large' />Check Team's Gallery</button></Link>
                    
                            <Grid
                                container
                                direction={"row"}
                                justifyContent="center"
                                alignItems='center'
                                spacing={0}
                            >
                                <Grid item xs={12} style={{ padding: '4px' }}>
                                    <b>Create Any AI Portrait Within Seconds</b>
                                    <br />
                                    <span style={{ fontSize: '14px' }}>Step 1. Select <u style={{ cursor: 'pointer' }} onClick={() => { updatedActiveMenu("ai-portrait") }}>"AI Portraits"</u> from the {isMobile ? "bottom" : "left side"} menu bar.</span>
                                    <br /><span style={{ fontSize: '14px' }}>Step 2. Explain the expected image.</span>
                                    <br /><span style={{ fontSize: '14px' }}>Step 3. (Optional) Provide face photo to generate the similar face.</span>
                                    <br /><span style={{ fontSize: '14px' }}>Step 4. (Optional) Set aspect ratio as per your requirement</span>
                                    <br /><span style={{ fontSize: '14px' }}>Step 5. Click Generate/Create</span>
                                    <br />
                                    <br />
                                    <ReactPlayer muted={true} style={{ border: '2px solid #8c52ff' }} playing controls width={isMobile ? "100%" : "640px"} height={isMobile ? "100%" : "360px"} url='https://youtu.be/n50gYoX5r-A' />
                                    <h6>Create a similar face photo</h6>
                                    <br />
                                </Grid>

                            </Grid>
            </LayoutAfterLogin>
        </>
    )
}

export default FacePhoto;