import React, { useState, useEffect } from 'react';
import HeaderOne from './header/HeaderOne';
import Announcement from './header/Announcement';
import GridLoader from "react-spinners/GridLoader";
import { useGlobalContext } from '../context/global_context';
import FooterOne from './footer/FooterOne';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

const Layout = ({ children }) => {
    const [showDialog, setShowDialog] = useState(false);

    const {
        loading,
        needLogin,
        setNeedLogin,
        updateRegisterEmail,
        updatePassword,
        submit,
        googleSignIn
    } = useGlobalContext();

    useEffect(() => {
        const lastDisplay = localStorage.getItem('lastAnnouncementDisplay');
        const currentTime = new Date().getTime();
        const fiveHours = 2 * 60 * 60 * 1000;
    
        // Check if 5 hours have passed since the last display
        if (!lastDisplay || currentTime - lastDisplay >= fiveHours) {
          setShowDialog(true);
          localStorage.setItem('lastAnnouncementDisplay', currentTime);
        }
    
        // Set a timeout to show the dialog every 5 hours
        const interval = setInterval(() => {
          setShowDialog(true);
          localStorage.setItem('lastAnnouncementDisplay', new Date().getTime());
        }, fiveHours);
    
        return () => clearInterval(interval);
    }, []);

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleVisitSite = () => {
        window.location.href = 'https://rainforest.zone';
      };

    return (
        <main>
            {showDialog && (
                <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={showDialog} onClose={handleCloseDialog}>
                <DialogTitle><b className='title' style={{color:'blue'}}>Try Our New Platform - Rainforest.Zone</b></DialogTitle>
                <DialogContent>
                <div className="edu-feature-list color-variation-1">
                        <div className="mb--10 row" style={{padding:'4%'}}>
                            Rainforest.zone has following awesome features; 
                            <ul>
                                <li>Multiple Image Generations</li>
                                <li>AI Fashion Photography</li>
                                <li>AI Product Photography</li>
                                <li>AI Portraits</li>
                                <li>AI Replace Face</li>
                                <li>AI Influencers</li>
                                <li>AI Images</li>
                            </ul>
                        </div>
                        <button style={{fontSize:'14px', width:'100%'}} className="edu-btn btn-white mb--10" onClick={handleVisitSite}><img src="/images/logo/rainforest-logo.png" />Explore Rainforest.zone </button>

                    </div>
                </DialogContent>
                <DialogActions>
                    <b className='edu-btn btn-small' style={{ color: 'blue', cursor: 'pointer' }} onClick={handleCloseDialog}>Close</b>
                </DialogActions>
            </Dialog>
            )}
            <HeaderOne />
            
            {/* <Announcement /> */}
            {children}
            {/* <Announcement /> */}
            <FooterOne />
            <div className={loading ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <GridLoader
                        color='#cc01ff'
                        loading={loading}
                        size={20} />
                </div>
            </div>

            <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={needLogin} onClose={() => setNeedLogin(false)}>
                <DialogTitle><b className='title'>Need To Login/Register</b></DialogTitle>
                <DialogContent>
                    <div className={loading ? 'parentDisable' : ''} width="100%">
                        <div className='overlay-box'>
                            <GridLoader
                                color='#cc01ff'
                                loading={loading}
                                size={20} />
                        </div>
                    </div>
                    <div className="edu-feature-list color-variation-1">
                        <div className="mb--10 row">
                            <button className="edu-btn btn-white mb--10" onClick={googleSignIn}><img src="/images/logo/g-logo.png" /> Sign in with Google </button>
                        </div>
                        {/* <div className="login-form">
                                    <div className="mb--10 row align-text-center">
                                        <br /><Divider />
                                    </div>
                                    <div className="mb--10 row">
                                        <input type="email" placeholder="Email" onChange={updateRegisterEmail} />
                                    </div>
                                    <div className="row mb--10">
                                        <input type="password" placeholder="Password" onChange={updatePassword} />
                                    </div>
                                </div> */}
                    </div>
                </DialogContent>
                <DialogActions>
                    <b className='edu-btn btn-small' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setNeedLogin(false)}>Close</b>
                    {/* <Button className='edu-btn btn-small' onClick={submit}
                                color="primary">
                                Submit
                            </Button> */}
                </DialogActions>
            </Dialog>
        </main>
    )
}
export default Layout;